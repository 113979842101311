<template>
  <div>
    <div class="video-container d-flex justify-content-center  flex-column" :style="bg_image" ref="video">
      <div class="video-container-content d-flex justify-content-center align-items-center">
        <div class="content-left text-center">
<!--          <h4>产品介绍视频</h4>-->
          <iframe style='width: 600px;height: 338px' frameborder='no' allowfullscreen mozallowfullscreen webkitallowfullscreen src='//go.plvideo.cn/front/video/preview?vid=dc5e7b95486185c786ccac11be30f85b_d'></iframe>
        </div>
        <div class="content-right">
          <h4 class="title">中壬云介绍</h4>
          <p>
            中壬云的研发初心紧贴现代中小微企业风控难点、盲点，可替代大部分企业信贷业务中繁琐的人工实操等环节，仅需几分钟即可在线完成企业债权测评并取得企业信贷价值报告，信贷价值报告涵盖企业实际经营的各方面数据，真实反映企业经营状况并且掌握企业现金流情况。
          </p>
          <p>
            中壬云债权评测系统依托原创PVE算力模型，融合多种算法，使用者仅需提供3份表：财报、流水以及纳税数值，通过对资料中的数据勾兑比例取值、交叉核验各项数据、自动剔除关联交易虚假流水等，深层次地完成流水反欺诈操作。
          </p>
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/footer.vue";
// import videojs from "video.js";
export default {
  components: {
    Footer,
  },
  data() {
    return {
      isPlay: false,
      player: null,
      listHight: null,
      bg_image: {
        backgroundImage: "url(" + require("@/static/image/video-3.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },
    };
  },
  mounted() {

    let fullHeight = document.documentElement.clientHeight - 90;
    this.$refs.video.style.height = `${fullHeight}px`;
  },
  beforeDestroy() {
  },
  methods: {
    suspend() {
      var vide = this.player;
      if (vide.paused()) {
        this.isPlay = true;
        vide.play();
      } else if (vide.play()) {
        vide.pause();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.isShow {
  display: none !important;
}
.video-container {
  .video-container-content {
    .content-left {
      margin-left: 200px;
      flex: 1;
      position: relative;
      .video-img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9;
      }
      .video-button {
        z-index: 10;
        font-size: 3em;
        line-height: 1.5em;
        height: 1.5em;
        width: 1.5em;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0;
        cursor: pointer;
        opacity: 1;
        border: 0.06666em solid #fff;
        background-color: red;
        background-color: rgba(43, 51, 63, 0.7);
        border-radius: 50%;
        transition: all 0.4s;
        .caret {
          position: absolute;
          top: 28%;
          left: 40%;
          width: 0;
          height: 0;
          border-left: 20px solid;
          border-bottom: 15px solid transparent;
          border-top: 15px solid transparent;
          color: #fff;
        }
      }
    }
    .content-right {
      margin-right: 200px;
      animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      .title {
        color: #11163c;
        font-size: 24px;
        text-align: center;
        padding-bottom: 10px;
      }
      margin-left: 20px;
      p {
        // width: 400px;
        text-indent: 2em;
        color: #808cec;
        line-height: 2;
        font-size: 18px;
        text-align: justify;
      }
    }
  }
  // 左进入
  @keyframes slide-in-left {
    0% {
      transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  // 右进入
  @keyframes slide-in-right {
    0% {
      transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
}
</style>
